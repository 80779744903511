<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Configurações de ingresso</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span>Ingressos</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-2 pl-0">
                  <h4 class="aluno_font_color">
                    Total de ingressos ({{ fastTrilhaIngressosFiltro.length }})
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-4 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Filtrar por nome"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mb-4 text-right">
                  <a
                    class="btn btn-primary ml-2 mr-2"
                    href="#"
                    @click.prevent="showModal('modalCriarTrilhaIngresso')"
                  >+ Criar ingresso</a>
                </div>
              </div>

              <!-- /fd-app-welcome -->
              <div class="row">
                <div
                  v-for="ingresso in pageOfIngressos"
                  :key="ingresso.id_trilha_ingresso"
                  class="col-12 mb-4 shadow p-3 mb-5 bg-white rounded"
                >
                  <h4>{{ ingresso.descricao_trilha }}</h4>
                  <tabs :options="{ useUrlFragment: false }">
                    <tab
                      prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                      name="Detalhes do ingresso"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6>Descrição do ingresso</h6>
                              <input
                                v-model="ingresso.descricao_trilha"
                                type="text"
                                class="form-control"
                                @keyup="ingresso.alteracao_pendente = true"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </tab>
                    <tab
                      name="Requisitos"
                      prefix="<i class='fa fa-file-pdf' aria-hidden='true'></i>&nbsp"
                      :suffix="'<span class=&quot;suffix&quot;>' + ingresso.total_requisitos + '</span>'"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6 text-left">
                          <h4>Requisitos</h4>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="exibeModalCriarRequisito(ingresso)"
                          >
                            <small>+ &nbsp; Adicionar requisito</small>
                          </button>
                        </div>
                        <div
                          v-if="ingresso.requisitosCarregou"
                          class="col-sm-12 col-md-12 col-lg-12 table-responsive"
                        >
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr class="bg-dark text-white">
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Sequência
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Descrição
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Etapa
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Data de início
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Data de fim
                                </th>
                                <th
                                  class="text-center"
                                  scope="col"
                                >
                                  Ações
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="ingresso.requisitos.length">
                              <tr
                                v-for="r in ingresso.requisitos"
                                :key="r.id_registro"
                              >
                                <td class="text-center">
                                  <input
                                    v-model="r.sequencia"
                                    style="width: 70px"
                                    type="number"
                                    min="1"
                                    class="form-control form-control-sm mx-auto"
                                    @change="corrigeSequenciaRequisito(ingresso, r)"
                                  >
                                </td>
                                <td>
                                  {{ r.descricao_atividade }}
                                </td>
                                <td class="text-center">
                                  {{ ajustaIngressoEtapa(r.etapa) }}
                                </td>
                                <td class="text-center">
                                  <span v-if="r.data_inicio">
                                    {{ new Date(r.data_inicio).toLocaleDateString() }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span v-if="r.data_fim">
                                    {{ new Date(r.data_fim).toLocaleDateString() }}
                                  </span>
                                </td>
                                <td class="text-center">                                 
                                  <a
                                    :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/ingresso/' + ingresso.id_trilha_ingresso + '/requisito/' + r.id_registro"
                                    class="btn btn-primary btn-sm mt-2 ml-2"
                                  >
                                    <small class="text-white">
                                      <i
                                        class="fa fa-cog"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Configurar
                                    </small>
                                  </a>
                                  <button
                                    class="btn btn-sm btn-danger mt-2 ml-2"
                                    @click="exibeModalExcluirRequisito(r)"
                                  >
                                    <small class="text-white">Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="7"
                                  class="text-center"
                                >
                                  <h6>Nenhum requisito cadastrado</h6>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            v-if="ingresso.alteracao_sequencia_pendente"
                            class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                          >
                            <button
                              class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                              @click.prevent="atualizaSequenciaRequisito(ingresso)"
                            >
                              <small>
                                <i
                                  class="fa fa-floppy-o"
                                  aria-hidden="true"
                                />
                                &nbsp;Salvar alterações
                              </small>
                            </button>
                            <button
                              class="btn btn-secondary btn-sm mt-2 ml-2"
                            >
                              <small>
                                <i
                                  class="fa fa-ban"
                                  aria-hidden="true"
                                />
                                &nbsp;Cancelar
                              </small>
                            </button>
                          </div>
                        </div>
                        <div
                          v-else
                          class="col-sm-12 col-md-12 col-lg-12 text-center"
                        >
                          <button
                            class="btn btn-primary btn-sm mt-2 ml-2 mb-2"
                            @click.prevent="getIngressoRequisitos(ingresso)"
                          >
                            <small>Exibir requisitos</small>
                          </button>
                        </div>
                      </div>
                    </tab>
                    <tab
                      name="Opções"
                      prefix="<i class='fa fa-file-pdf' aria-hidden='true'></i>&nbsp"
                    >
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <button
                            class="btn btn-danger btn-sm mt-2 ml-2"
                            @click="exibeModalExcluirIngresso(ingresso)"
                          >
                            <small>
                              <i
                                class="fa fa-ban"
                                aria-hidden="true"
                              />
                              &nbsp; Excluir ingresso
                            </small>
                          </button>
                        </div>
                      </div>
                    </tab>
                  </tabs>
                  <div
                    v-if="ingresso.alteracao_pendente"
                    class="col-sm-12 col-md-12 col-lg-12 mt-2 text-center"
                  >
                    <button
                      class="btn btn-primary btn-sm mt-2 ml-2 blink_me"
                      @click="atualizaTrilhaIngresso(ingresso)"
                    >
                      <small>
                        <i
                          class="fa fa-floppy-o"
                          aria-hidden="true"
                        />
                        &nbsp;Salvar alterações
                      </small>
                    </button>
                    <button
                      class="btn btn-secondary btn-sm mt-2 ml-2"
                      @click.prevent="getTrilhaIngressos()"
                    >
                      <small>
                        <i
                          class="fa fa-ban"
                          aria-hidden="true"
                        />
                        &nbsp;Cancelar
                      </small>
                    </button>
                  </div>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="10"
                    :items="fastTrilhaIngressosFiltro"
                    @changePage="pageOfIngressos = $event"
                  />
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarTrilhaIngresso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo ingresso</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTrilhaIngresso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
              <label>Descrição</label>
              <input
                v-model="fastTrilhaIngressoNovo.descricao_trilha"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarTrilhaIngresso()"
                >
                  Criar ingresso
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    
    <modal
      name="modalExcluirIngresso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Exclui ingresso?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirIngresso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirTrilhaIngresso()"
                >
                  <small>Confirmar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    
    <modal
      name="modalCriarRequisito"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Criar requisito</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRequisito')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <div class="row">
            <div
              v-if="fastIngressoRequisito.etapa != 'DC'"
              class="col-sm-12 col-md-12 col-lg-6"
            >
              <label>Descrição da atividade</label>
              <div class="form-group">
                <input
                  v-model="fastIngressoRequisito.descricao_atividade"
                  class="form-control"
                  type="text"
                  placeholder="Descrição da atividade"
                >
              </div>              
            </div>

            <div
              v-if="fastIngressoRequisito.etapa == 'DC'"
              class="col-sm-12 col-md-12 col-lg-6"
            >
              <label>Tipo de documento</label>
              <button
                class="btn btn-sm btn-primary ml-2 pt-0 pb-0"
                @click="showModal('modalCriarTipoDocumento', 'modalCriarRequisito')"
              >
                <small>Criar novo</small>                
              </button>
              <div class="form-group">
                <select
                  v-model="fastRequisitoDocumento.tipo_documento"
                  class="form-control"
                >
                  <option
                    v-for="documento in fastSecretariaTipoDocumentos"
                    :key="documento.tipo_documento"
                    :value="documento.tipo_documento"
                  >
                    {{ documento.nome_documento }}
                  </option>
                </select>
              </div>
            </div>
            
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label>Etapa</label>
              <div class="form-group">
                <select
                  v-model="fastIngressoRequisito.etapa"
                  class="form-control"
                  @change="verificaTipoRequisito()"
                >
                  <option value="IA">
                    Inscrição do aluno
                  </option>
                  <option value="PO">
                    Prova Objetiva
                  </option>
                  <option value="PD">
                    Prova Dissertativa
                  </option>
                  <option value="CD">
                    Correção Dissertativa
                  </option>
                  <option value="MT">
                    Matrícula
                  </option>
                  <option value="RA">
                    Geração do RA
                  </option>
                  <option value="GC">
                    Geração do Contrato
                  </option>
                  <option value="GB">
                    Geração de Boletos
                  </option>
                  <option value="CM">
                    Confirmação de Mátricula
                  </option>
                  <option value="LA">
                    Lista de aprovados
                  </option>
                  <option value="DC">
                    Documento
                  </option>
                </select>
              </div>
            </div>
            
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label>Data início</label>
              <div class="form-group">
                <input
                  v-model="fastIngressoRequisito.data_inicio"
                  class="form-control"
                  type="date"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label>Data fim</label>
              <div class="form-group">
                <input
                  v-model="fastIngressoRequisito.data_fim"
                  class="form-control"
                  type="date"
                >
              </div>
            </div>
            <div class="col-12">                
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarRequisito()"
                >
                  <small>Criar requisito</small>                    
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirRequisito"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Exclui requisito?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirRequisito')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirRequisito()"
                >
                  <small>Confirmar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarTipoDocumento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo documento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTipoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome do documento</label>
              <input
                v-model="fastSecretariaTipoDocumentoNovo.nome_documento"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaTipoDocumento()"
                >
                  Criar documento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaIngressos",
  components: {
    Pagination
  },

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastTrilhaIngressoNovo: {
            id_trilha_ingresso: 0, data_criacao: 0, id_usuario_criou: 0, data_ultima_alteracao: 0, id_usuario_alterou: 0, descricao_trilha: "", template: 0, id_plataforma: 0
      },
      fastTrilhaIngressoEditar: {
            id_trilha_ingresso: 0, data_criacao: 0, id_usuario_criou: 0, data_ultima_alteracao: 0, id_usuario_alterou: 0, descricao_trilha: "", template: 0, id_plataforma: 0
      },
      fastIngressoRequisito: {
        id_trilha_ingresso: "",
        etapa: "IA",
        sequencia: "",
        descricao_atividade: "",
        data_inicio: "",
        data_fim: ""
      },
      fastIngressoRequisitoEditar: {
        id_registro: "",
        id_trilha_ingresso: "",
        etapa: "IA",
        sequencia: "",
        descricao_atividade: "",
        data_inicio: "",
        data_fim: ""
      },
      fastTrilhaIngressosTotal: [],
      fastTrilhaIngressosFiltro: [],
      pageOfIngressos: [],
      // Tipo documentos
      fastSecretariaTipoDocumentoNovo: {
        tipo_documento: 0,
        nome_documento: "",
        id_plataforma: 0
      },
      fastSecretariaTipoDocumentos: [],
      fastRequisitoDocumento: {
        id_documentos: 0,
        tipo_documento: 0,
        id_registro: 0
      }
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Buscar ingressos
            this.getTrilhaIngressos();
            // Tipo documentos
            this.getSecretariaTipoDocumentos(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    getTrilhaIngressos() {
      this.promiseGetTrilhaIngressos(this.$route.params.id_plataforma).then(obj => {
        Array.from(obj).map(e => {
          e.alteracao_pendente = false;
          e.alteracao_sequencia_pendente = false;
          e.requisitos = [];
          e.requisitosCarregou = false;
          this.getIngressoRequisitos(e);
        })
        this.fastTrilhaIngressosTotal = obj
        this.fastTrilhaIngressosFiltro = obj
        console.log("promiseGetTrilhaIngressos", obj)
        }).catch((e) => {
        console.log("promiseGetTrilhaIngressos", e)
        this.exibeToasty("Nenhum ingresso configurado", "error");
      })
    },
    getIngressoRequisitos(ingresso) {
      
      this.promiseGetFastApi("api/fast_secretaria_sequencia/lista", "id_trilha_ingresso=" + ingresso.id_trilha_ingresso).then(obj => {
        console.log("getIngressoRequisitos", obj)
        ingresso.requisitosCarregou = true
        ingresso.requisitos = obj;
        
      }).catch(e => {
        console.log(e);
      })
    },
    async promiseGetTrilhaIngressos(id_plataforma) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_secretaria_tirlha_ingresso/lista?id_plataforma=" +
              id_plataforma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            resolve(json)
          } else {
            reject(false);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    criarTrilhaIngresso() {
      const fast_secretaria_tirlha_ingresso = {
        descricao_trilha: this.fastTrilhaIngressoNovo.descricao_trilha, 
        template: 'N', 
        id_plataforma: this.$route.params.id_plataforma        
      };
      this.promiseCriarTrilhaIngresso(fast_secretaria_tirlha_ingresso).then((res) => {
        this.exibeToasty("Ingresso criado com sucesso", "success");
        this.hideModal("modalCriarTrilhaIngresso");
        // Buscar ingressos
        this.getTrilhaIngressos();
      }).catch((e) => {
        this.exibeToasty("Erro ao criar ingresso", "error");
      })
    },
    async promiseCriarTrilhaIngresso(fast_secretaria_tirlha_ingresso) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_secretaria_tirlha_ingresso/insere",
            this.fastAjaxOptions("POST", JSON.stringify(fast_secretaria_tirlha_ingresso ))
          );
          let json = await resp.json();
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    atualizaTrilhaIngresso(ingresso) {      
      this.$store.state.fastCarregando = true;
      this.promiseAtualizarFastApi(ingresso, "fast_secretaria_tirlha_ingresso").then(e => {
        this.exibeToasty("Salvo com sucesso", "success");
        ingresso.alteracao_pendente = false;
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty("Erro ao salvar alterações", "error");
        this.$store.state.fastCarregando = false;
      }
      );
    },
    exibeModalExcluirIngresso(ingresso) {
      this.fastTrilhaIngressoEditar = ingresso;
      this.showModal("modalExcluirIngresso");
    },
    async excluirTrilhaIngresso(){
      const fast_secretaria_tirlha_ingresso = {
        id_trilha_ingresso: this.fastTrilhaIngressoEditar.id_trilha_ingresso,
        id_plataforma: this.$route.params.id_plataforma
      };

      this.promiseExcluirFastApi(fast_secretaria_tirlha_ingresso, "fast_secretaria_tirlha_ingresso").then(e => {
        this.exibeToasty("Excluído com sucesso", "success");
        this.getTrilhaIngressos();
        this.hideModal("modalExcluirIngresso");
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    exibeModalCriarRequisito(ingresso) {
      this.fastTrilhaIngressoEditar = ingresso;
      this.showModal("modalCriarRequisito");
    },
    async criarRequisito() {
      if (this.fastIngressoRequisito.etapa == 'DC') {
            this.fastIngressoRequisito.descricao_atividade = this.fastRequisitoDocumento.tipo_documento;
      }
      const fast_secretaria_sequencia = {
        id_trilha_ingresso: this.fastTrilhaIngressoEditar.id_trilha_ingresso, 
        etapa: this.fastIngressoRequisito.etapa,
        descricao_atividade: this.fastIngressoRequisito.descricao_atividade,
        data_inicio: this.fastIngressoRequisito.data_inicio,
        data_fim: this.fastIngressoRequisito.data_fim
      };
      this.promiseInserirFastApi(fast_secretaria_sequencia, "fast_secretaria_sequencia").then((res) => {
        console.log(res)
        if (res.length) {
            this.exibeToasty("Requisito criado com sucesso", "success");
            this.hideModal("modalCriarRequisito");
            // Buscar ingressos
            this.getTrilhaIngressos();
          
        } else {
          this.exibeToasty("Erro ao criar requisito", "error");
        }        
        
      }).catch((e) => {
        this.exibeToasty("Erro ao criar requisito", "error");
      });
    },
    corrigeSequenciaRequisito(ingresso, requisito) {
      ingresso.alteracao_sequencia_pendente = true;
      ingresso.requisitos.sort(this.reornedarArrayObjetos("sequencia"));
    },
    atualizaSequenciaRequisito(ingresso) {
      this.$store.state.fastCarregando = true;
      ingresso.requisitos.forEach((e, index) => {
        e.sequencia = index + 1;
        setTimeout(() => {
          this.salvaAtualizaSequenciaRequisito(e, index, ingresso, ingresso.requisitos);
        }, 2000);
      });
    },
    async salvaAtualizaSequenciaRequisito(e, index, ingresso, requisitos) {
      const fast_secretaria_sequencia = {
        id_registro: e.id_registro,
        id_trilha_ingresso: e.id_trilha_ingresso,
        etapa: e.etapa,        
        data_inicio: e.data_inicio,
        data_fim: e.data_fim,
        sequencia: e.sequencia,
        descricao_atividade: e.descricao_atividade,
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_secretaria_sequencia/atualiza",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_secretaria_sequencia)
          )
        );

        let json = await resp.json();
        let obj = Array.from(json);

        if (requisitos.length == index + 1) {
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Sequência atualizada com sucesso",
          });
          this.$store.state.fastCarregando = false;
          ingresso.alteracao_sequencia_pendente = false;
        }
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao atualizar sequência",
        });
        console.log("Erro", e);
      }
    },
    exibeModalExcluirRequisito(requisito) {
      this.fastIngressoRequisitoEditar = requisito;
      this.showModal("modalExcluirRequisito");
    },
    async excluirRequisito(){
      const fast_secretaria_sequencia = {
        id_registro: this.fastIngressoRequisitoEditar.id_registro,
        id_plataforma: this.$route.params.id_plataforma
      };

      this.promiseExcluirFastApi(fast_secretaria_sequencia, "fast_secretaria_sequencia").then(e => {
        this.exibeToasty("Excluído com sucesso", "success");
        this.getTrilhaIngressos();
        this.hideModal("modalExcluirRequisito");
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
    ajustaIngressoEtapa(etapa) {
      switch (etapa) {
        case 'IA':
          return "Inscrição do aluno";
          break;
        case 'PO':
          return "Prova objetiva";
          break;
        case 'PD':
          return "Prova Dissertativa";
          break;
        case 'CD':
          return "Correção Dissertativa";
          break;
        case 'MT':
          return "Matrícula";
          break;
        case 'RA':
          return "Geração do RA";
          break;
        case 'GC':
          return "Geração do Contrato";
          break;
        case 'GB':
          return "Geração de Boletos";
          break;
        case 'CM':
          return "Confirmação de Mátricula";
          break;
        case 'LA':
          return "Lista de Aprovados";
          break;
        case 'DC':
          return "Documento";
          break;
        default:
          break;
      }
    },
    getSecretariaTipoDocumentos(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_tipo_documento/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaTipoDocumentos", obj);
        if (obj.length) this.fastSecretariaTipoDocumentos = obj;
      }).catch(e => {
        console.log(e);
      })
    },
    verificaTipoRequisito() {
      if (this.fastIngressoRequisito.etapa == "DC") {        
        if (!this.fastSecretariaTipoDocumentos.length) {
          this.hideModal("modalCriarRequisito");
          this.showModal("modalCriarTipoDocumento");
        } else {
          this.fastRequisitoDocumento.tipo_documento = this.fastSecretariaTipoDocumentos[0].tipo_documento;
        }
      }
    },
    async criarSecretariaTipoDocumento() {
      const fast_secretaria_tipo_documento = {
        nome_documento: this.fastSecretariaTipoDocumentoNovo.nome_documento,
        id_plataforma: this.$route.params.id_plataforma
      };
      console.log("fast_secretaria_tipo_documento", fast_secretaria_tipo_documento)
      this.promiseInserirFastApi(fast_secretaria_tipo_documento, "fast_secretaria_tipo_documento").then((res) => {
        console.log("criarSecretariaTipoDocumento", res)
        this.exibeToasty("Documento criado com sucesso", "success");
        this.hideModal("modalCriarTipoDocumento");
        this.showModal("modalCriarRequisito");
        // Tipo documentos
        this.fastSecretariaTipoDocumentos = res;
      }).catch((e) => {
        this.exibeToasty("Erro ao criar requisito", "error");
      });
    }
  
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}


.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
